import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import './assets/styles/_index.scss';
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const { CTALink } = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")
const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const TileBlock = (props) => {
    var imagename = "page.tile_section_image.tile_image";

    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.tile_section_image_Transforms) {
        processedImages = props?.imagetransforms?.tile_section_image_Transforms;
    }
    return (
        <section className="tile-block-wrapper section-p-120">
            <Container>
                <Row className="d-flex align-items-center">
                    <Col xl={5} className="order-xl-1 order-2">
                        <div className="tile-block-content-wrapper">
                            {props.title && <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}><div className="atlas-text-sm">{props.title}</div></ScrollAnimation>}
                            {props.description && <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={200} offset={100}><ContentModule Content={props.description?.data?.description} /></ScrollAnimation>}
                            <div className="tile-block-btn-wrapper">
                                <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={300} offset={100}>
                                    <CTALink class="button button-primary" link={props.cta_link} title={props.cta_title} target_window={props.cta_link?.target_window}>{props.cta_title}</CTALink>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </Col>
                    <Col xl={1} className="order-xl-2"></Col>
                    <Col xl={6} className="order-xl-3 order-1 tile-image-parent">
                        <div className="tile-block-img-wrapper img-wrapper">
                            <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
                                <ImageModule ImageSrc={props?.image} altText={props?.image?.alternativeText} imagetransforms={processedImages} renderer="srcSet" imagename={imagename} strapi_id={props?.id} classNames="img-fluid" />
                            </ScrollAnimation>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default TileBlock