import React from "react";
import { Tab, Tabs, InputGroup, Form, Button } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import loadable from "@loadable/component";
import GoogleReviews from "../GoogleReviews/GoogleReviews";
import { PageLinks } from "../../common/site/page-static-links";
import './assets/styles/_index.scss';
const SearchBoxFilter = loadable(() => import("../SearchBoxFilter/SearchBoxFilter"));
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const BannerSearch = (props) => {
    const BuyClick = () => {
        var area = document.getElementById('predictive-search-input').value
        if (area) {
            navigate(`/property/for-sale/in-` + area.toLowerCase() + '/')
        } else {
            navigate(`/${PageLinks.results_sales}/`)
        }
    }
    const RentClick = () => {
        var area = document.getElementById('predictive-search-input').value
        if (area) {
            navigate(`/property/to-rent/in-` + area.toLowerCase() + '/')
        } else {
            navigate(`/${PageLinks.results_lettings}/`)
        }
    }
    return (
        <div className="banner-search-wrapper">
            {props.banner_title && <h1>{props.banner_title}</h1>}
            {props.banner_content && <ContentModule Content={props.banner_content?.data?.banner_content} />}
            {props.show_search &&
                <Tabs
                    defaultActiveKey="buy"
                    className="banner-search-tabs"
                >
                    <Tab eventKey="buy" title="Buy">
                        <InputGroup className="banner-search-input-group">
                            <SearchBoxFilter />
                            <Button variant="outline-secondary" className="banner-search-btn" id="basic-addon1" onClick={BuyClick}>
                                <i className="icon icon-banner-search"></i>
                            </Button>
                        </InputGroup>
                    </Tab>
                    <Tab eventKey="rent" title="Rent">
                        <InputGroup className="banner-search-input-group">
                            <SearchBoxFilter />
                            <Button variant="outline-secondary" className="banner-search-btn" id="basic-addon1" onClick={RentClick}>
                                <i className="icon icon-banner-search"></i>
                            </Button>
                        </InputGroup>
                    </Tab>
                </Tabs>}

            {props.show_review && <GoogleReviews />}
        </div>
    )
}

export default BannerSearch