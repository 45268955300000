import React from "react";
import loadable from "@loadable/component";
import { graphql } from 'gatsby'
import Layout from "../components/layout";
import SEO from "../components/seo";
import PropertyImg1 from "../images/properties_img_1.png";
import PropertyImg2 from "../images/properties_img_2.png";
import PropertyImg3 from "../images/properties_img_3.png";
import ReviewsImg from "../images/reviews_img.png";
import NewsImg1 from "../images/news_img_1.png";
import NewsImg2 from "../images/news_img_2.png";
import NewsImg3 from "../images/news_img_3.png";

import Banner from "../components/Banner/HomeBanner";
import TileBlock from "../components/TileBlock/TileBlock";
// const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"));
const StatsModule = loadable(() => import("../components/StatsModule/StatsModule"));
const ServicesOffer = loadable(() => import("../components/ServicesOffer/ServicesOffer"));
const ValuationModule = loadable(() => import("../components/ValuationModule/ValuationModule"));
const PropertySlider = loadable(() => import("../components/PropertySlider/PropertySlider"));
const ReviewsModule = loadable(() => import("../components/ReviewsModule/ReviewsModule"));
const NewsInsightsSlider = loadable(() => import("../components/NewsInsightsSlider/NewsInsightsSlider"));

const IndexPage = ({ data }, props) => {
  const PageData = data?.strapiPage
  const siteData = data?.strapiSiteConfig
  return (
    <Layout popularSearch={PageData?.select_popular_search?.title}>

      {PageData.banner && <Banner title={PageData.title} {...PageData.banner} id={PageData.strapi_id} />}
      {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
        return (
          <>
            {module.strapi_component === "page-modules.image-and-content" && module.layout === "default" && <TileBlock {...module} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms} />}

            {module.strapi_component === "page-modules.image-and-content" && module.layout === "fullwidth" && <ValuationModule {...module} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms}/>}

            {module.strapi_component === "page-modules.text-module" && module.add_services.length > 0 && <ServicesOffer {...module} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms}/>}

            {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties" && <PropertySlider /> }

            {module.strapi_component === "page-modules.global-module" && module.select_module === "google_reviews_slider" && <ReviewsModule reviewsImg={ReviewsImg} /> }

            {module.strapi_component === "page-modules.global-module" && module.select_module === "latest_news_slider" && <NewsInsightsSlider /> }

            {module.strapi_component === "page-modules.stats-module" && <StatsModule {...module} /> }
          </>
        )
      })}

    </Layout>
  )
}


export const Head = ({ data }, props) => {
  const PageData = data?.strapiPage
  const siteData = data?.strapiSiteConfig

  var schemaSameAs = ''
  if (siteData?.facebook_link.length > 1) {
    schemaSameAs = siteData?.facebook_link + ','
  }
  if (siteData?.twitter_link.length > 1) {
    schemaSameAs += siteData?.twitter_link + ','
  }
  if (siteData?.instagram_link.length > 1) {
    schemaSameAs += siteData?.instagram_link + ','
  }
  if (siteData?.linkedin_link.length > 1) {
    schemaSameAs += siteData?.linkedin_link + ','
  }

  var ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": process.env.GATSBY_SITE_URL,
    "name": process.env.GATSBY_SITE_NAME,
    "alternateName": process.env.GATSBY_SITE_NAME,
    "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`,
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": siteData?.mobile_device_phone,
      "areaServed": process.env.GATSBY_DEFAULT_AREA,
      "contactType": "Sales"
    },
    "sameAs": schemaSameAs.replace(/,\s*$/, "").split(',')
  };

  return (
    <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      />
    </SEO>
  )
}


export default IndexPage



export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
              ...GlobalModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
              ...ImageAndContentFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_TEXT_MODULE {
              ...TextModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_STATS_MODULE {
              ...StatsModuleFragment
            }
        }
    }

    
    strapiSiteConfig {
        twitter_link
        instagram_link
        linkedin_link
        facebook_link
        add_contact_details {
          phone
        }
      }
  }
`