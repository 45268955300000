import React from "react";
import { Link } from "gatsby";
import GoogleReviews from "../GoogleReviews/GoogleReviews";
import './assets/styles/_index.scss';
const { CTALink } = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const BannerContent = (props) => {
    const JobSection = () => {
        const element = document.querySelector(".career-positions-wrapper");
        if (element) {
            window.scrollTo({
                behavior: 'smooth',
                top:
                    element.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    10,
            })
        }
    }
    return (
        <div className="banner-content-wrapper">
            <h1>{props.title}</h1>
            {props.banner_content && <ContentModule Content={props.banner_content?.data?.banner_content} />}
            <ul className="list-inline">
                {props.cta_1_title && props.cta_1_link &&
                    <li className="list-inline-item">
                        {props.tag !== "career-landing" ?
                            <CTALink class="button button-primary-outline " link={props.cta_1_link} title={props.cta_1_title} target_window={props.cta_1_link.target_window} /> : <button class="button button-primary-outline" onClick={()=>JobSection()} >{props.cta_1_title}</button>}
                    </li>
                }
                {props.cta_2_title && props.cta_2_link &&
                    <li className="list-inline-item">
                        <CTALink class="button button-secondary-outline-white" link={props.cta_2_link} title={props.cta_2_title} target_window={props.cta_2_link.target_window} />
                    </li>
                }
            </ul>

            {props.show_review && <GoogleReviews />}
        </div>
    )
}

export default BannerContent