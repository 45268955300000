import React from "react";
import './assets/styles/_index.scss';

import GoogleReviewImg from "../../images/google_reviews_img.svg";

const GoogleReviews = (props) => {
    return (
        <div className="google-reviews-wrapper d-flex align-items-center">
            <img src={GoogleReviewImg} className="reviews-img" alt="Atlas" />
            <div className="google-reviews-text"><span>4.9+/5</span> from 348 reviews</div>
        </div>
    )
}

export default GoogleReviews