import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BannerContent from "../BannerContent/BannerContent";
import BannerSearch from "../BannerSearch/BannerSearch";
import HomeBannerImage from "./HomeBannerImage";
import './assets/styles/_index.scss';

const Banner = (props) => {
    return (
        <section className={`banner d-flex align-items-end ${props.bannerClass}`}>
            <HomeBannerImage />
            <div className="overlay-bg"></div>
            <Container className="banner-search-container">
                <Row>
                    <Col lg={7}>
                        {
                            props.tag === "landing-banner" ?
                            <BannerContent
                                title={props.title}
                                desc={props.desc}
                                btn1={props.btn1}
                                btn2={props.btn2}
                            />
                            :
                            <BannerSearch {...props}/>
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Banner